<template>
  <body class="auth-wrapper">
    <div class="auth-wrapper auth-bg auth-v2">
      <!-- Brand logo-->
      <nav class="navbar auth-bg">
        <div class="container-fluid  auth-bg p-0">
          <b-link class="brand-logo mx-auto ml-md-4 mb-2">
            <logo-sao-bento />
          </b-link>
        </div>
      </nav>


      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center float-left auth-bg"
      >
        <div class="d-lg-flex ml-5" style="width: 70%">
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5 mt-3 "
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            D
            class="font-weight-bold mb-1"
          >
            Não lembra sua senha? 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            Informe o seu CPF, CNPJ ou E-mail que enviaremos instruções para que você possa cadastrar uma nova senha agora.
          </b-card-text>

          <!-- alert -->
          <b-alert
            v-model="showAlert"
            :variant="alertVariant"
            dismissible
            class="mb-0"
          >
            <div class="alert-body">
              {{alertMessage}}
            </div>
          </b-alert>
            
          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group
                label="CPF, CNPJ ou E-mail"
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="login"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-email"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
              >
                Enviar Instruções
              </b-button>
            </b-form>
          </validation-observer>

          <div class="demo-spacing-0">
          </div>
          <p class="text-center mt-2">
            <b-link :to="{name:'login'}">
              <feather-icon icon="ChevronLeftIcon" /> Voltar ao Login
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </div>
  </body>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import ptBR from 'vee-validate/dist/locale/pt_BR.json'
import LogoSaoBento from '../../layouts/components/LogoSaoBento.vue'
import {
  BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton, BAlert
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
import services from '@/services'

localize('pt_BR', ptBR)

export default {

    components: {
        LogoSaoBento,
        BCol,
        BLink,
        BImg,
        BForm,
        BButton,
        BFormGroup,
        BFormInput,
        BCardTitle,
        BCardText,
        BAlert,
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            login: '',
            sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
            required,
            email,
            showAlert: false,
            alertVariant: '',
            alertMessage: '',
        }
    },

    computed: {
        imgUrl() {
        if (store.state.appConfig.layout.skin === 'dark') {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
            return this.sideImg
        }
            return this.sideImg
        },
    },

    methods: {
      async validationForm() {
          this.$refs.simpleRules.validate().then(success => {
              if (success) {
                  this.sendResetEmail()
              }
          })
      },

      async sendResetEmail() {
      this.showAlert = false

      this.isLoading = true
          const {errors} = await services.auth.sendResetEmail(this.login, this.$apolloProvider.defaultClient)
          if (errors) {
            this.showAlert = true
            this.alertVariant = 'danger'
            this.alertMessage = 'Erro ao enviar as instruções para seu e-mail!'
          }
          else{
            this.showAlert = true
            this.alertVariant = 'success'
            this.alertMessage = 'Enviamos um email com as intruções para redefinição de senha!'
          }            
          this.isLoading = false         
        },
    },
}
</script>


<style lang="scss">
@import '@core/scss/base/pages/page-auth.scss';
.brand-logo {
  svg {
    height: 70px;
    width: 160px;
  }
}
html[dir='rtl'] {
  svg.feather {
    transform: rotate(180deg);
  }
}
</style>